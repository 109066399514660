@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,100&family=Raleway:wght@500&display=swap);
/*
.room {
    height: 400px;
    border: 1px solid red;
}

html,
body,
#root,
.main {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
}
*/

html,
body,
#root,
.main {
    margin-bottom: 50px;
}

#root {
    overflow: hidden;
}

.selectDTDImage {
    min-width: 80px;
    max-width: 200px;
}

.testSegmentGroupClass {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.testSegmentClass {
    width: 100%;
}

.testSidebarClass .segment {
    border: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
}

.testSidebarClass .segments {
    border: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
}

.testSidebarMenuItemClass {
    padding-top: 0px;
    padding-bottom: 0px;
}

/*
.testSidebarClass .ui.menu {
  border: 0px;
}

.testSidebarClass .menu div {
  padding-top: 0px;
  padding-bottom: 0px;
}

.testSidebarMenuItemsClass .menu div {
  padding-top: 0px;
  padding-bottom: 0px;
}
*/
.testSidebarClass .segments .testSegmentClass div {
    border: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

/*
.ui .fluid .ui .top .visible .sidebar .testSidebarClass .menu {
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.testSidebarMenuItemsClass .ui.menu .item div {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: red;
}
*/

.testSidebarClass .ui.menu .item {
    padding-top: 0px;
    padding-bottom: 0px;
}

/*
.menuImage {
    height: 210px;
    width: 100px;
}*/

.selectCorpusesSegmentClass .item {
    background-color: #00b5ad;
}

.selectCorpusesSegmentClass .item::before {
    background: greenyellow;
}

.selectCorpusesSegmentClass .row {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}


@media screen and (max-height: 800px) {

    .doorFillsMenu img {
        height: 100px;
    }

    .corpusesMenu img {
        height: 100px;
    }

    .corpusesMenuItem {
        width: 60px;
        padding: 10px;
    }

    .corpusesMenuItem .item {
        border: 0px;
        padding: 0px;
    }

    .corpusesMenuItem .menu-item {
        width: 50px;
        margin: 0px;
    }

    .ui.grid.selectSpaceGridContent > .row {
        padding: 5px;
    }
}

.ui.menu .item > img:not(.ui) {
    width: 100%;
}

.arrow-prev {
    font-weight: bold;
    font-size: 40px;
}

.arrow-next {
    font-weight: bold;
    font-size: 40px;
}

.ui.menu .item:before {
    background-color: #ffffff;
}

.ui.menu .item {
    padding-top: 0px;
    padding-bottom: 0px;
}

.topStepGroupContent {
    text-align: center;
}

.topStepGroupContent i.home.big.icon {
    margin-bottom: 5px;
    vertical-align: center;
}

.ui.card.woodDecorCard > .image > img {
    display: block;
    max-height: 200px;
    border-radius: inherit
}

.ui.card.doorFillsCard > .image > img {
    display: block;
    max-height: 200px;
    border-radius: inherit
}

@media screen and (max-width: 800px) {
    .ui.card.doorProfileCard,
    .ui.card.doorFillsCard,
    .ui.card.woodDecorCard {
        width: 150px;
        font-size: 10px;
    }

    .ui.card.woodDecorCard > .image > img,
    .ui.card.doorFillsCard > .image > img,
    .ui.card.doorProfileCard > .image > img {
        max-height: 100px;
    }

    .ui.card > .content > .header:not(.ui).doorProfileCardHeader,
    .ui.card > .content > .header:not(.ui).doorFillsCardHeader,
    .ui.card > .content > .header:not(.ui).woodDecorCardHeader {
        font-size: 10px;
        padding-top: 5px;
    }
}

@media screen and (min-width: 800px) {
    .ui.card > .content > .header:not(.ui).doorProfileCardHeader,
    .ui.card > .content > .header:not(.ui).doorFillsCardHeader,
    .ui.card > .content > .header:not(.ui).woodDecorCardHeader {
        font-size: 15px;
        padding-top: 5px;
    }
}

.ui.card.corpusesVariantsCard > .image > img {
    display: block;

    border-radius: inherit
}

.corpusesVariantsMenu {
    width: auto;
}

.corpusesVariantMenuItem {
    padding: 10px;
}

selectCorpusesVariantsSegmentGroup {
    background-color: #0d71bb;
}

selectCorpusesVariant {
    flex-direction: row;
    background-color: #9f3a38;
    padding: 10px;
    border: #9f3a38 10px solid;
}

@media screen and (max-width: 600px) {
    .resizableCardImage img {
        border: 1px solid blue;
        width: 100px;
    }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
    .resizableCardImage img {
        border: 1px solid red;
    }
}

.selectObjType {
    cursor: pointer;
    margin: 10px;
}

.selectCorpusesSetSpacesCountSegmentClass {
    margin-top: 10px;
}

.bottomMenuHeaderTitle {
    text-align: center;
}

.selectSpaceMenuItem {
    text-align: center;
}

.selectSpaceMenuDoors {
    cursor: pointer;
}

@-webkit-keyframes blinkSize {
    50% {
        border-width: 0px;
    }
}

@keyframes blinkSize {
    50% {
        border-width: 0px;
    }
}

.selectDoorFill{
    border-width: 2px;
    border-color: green;
    border-style: solid;
    -webkit-animation-name: blinkSize ;
            animation-name: blinkSize ;
    -webkit-animation-duration: .5s ;
            animation-duration: .5s ;
    -webkit-animation-timing-function: step-end ;
            animation-timing-function: step-end ;
    -webkit-animation-iteration-count: infinite ;
            animation-iteration-count: infinite ;
    -webkit-animation-direction: alternate ;
            animation-direction: alternate ;
}

.blinkBordersContent{
    border-width: 2px;
    border-color: green;
    border-style: solid;
    -webkit-animation-name: blinkSize ;
            animation-name: blinkSize ;
    -webkit-animation-duration: .5s ;
            animation-duration: .5s ;
    -webkit-animation-timing-function: step-end ;
            animation-timing-function: step-end ;
    -webkit-animation-iteration-count: infinite ;
            animation-iteration-count: infinite ;
    -webkit-animation-direction: alternate ;
            animation-direction: alternate ;
}

.blinkBordersContent:focus, .blinkBordersContent:hover {
    -webkit-animation: wobble 1s 1;
            animation: wobble 1s 1;
    /*background-color: #80FF80;*/
}

.blinkBordersBottom{
    border-width: 0px;
    border-bottom-width: 2px;
    border-color: green;
    border-style: solid;
    -webkit-animation-name: blinkSize ;
            animation-name: blinkSize ;
    -webkit-animation-duration: .5s ;
            animation-duration: .5s ;
    -webkit-animation-timing-function: step-end ;
            animation-timing-function: step-end ;
    -webkit-animation-iteration-count: infinite ;
            animation-iteration-count: infinite ;
    -webkit-animation-direction: alternate ;
            animation-direction: alternate ;
}

.blinkBordersBottom:focus, .blinkBordersBottom:hover {
    -webkit-animation: wobbleBottom 1s 1;
            animation: wobbleBottom 1s 1;
}

@-webkit-keyframes blink {
    50% {
        border-color: red;
    }
}

@keyframes blink {
    50% {
        border-color: red;
    }
}

.blinkBordersContentEmpty{
    border-width: 2px;
    border-color: green;
    border-style: solid;
    -webkit-animation-name: blink ;
            animation-name: blink ;
    -webkit-animation-duration: .5s ;
            animation-duration: .5s ;
    -webkit-animation-timing-function: step-end ;
            animation-timing-function: step-end ;
    -webkit-animation-iteration-count: infinite ;
            animation-iteration-count: infinite ;
    -webkit-animation-direction: alternate ;
            animation-direction: alternate ;
}

.blinkBordersContentEmpty:focus, .blinkBordersContentEmpty:hover {
    -webkit-animation: wobble 1s 1;
            animation: wobble 1s 1;
    /*background-color: #80FF80;*/
}

.blinkBordersBottomEmpty{
    border-width: 0px;
    border-bottom-width: 2px;
    border-color: green;
    border-style: solid;
    -webkit-animation-name: blink ;
            animation-name: blink ;
    -webkit-animation-duration: .5s ;
            animation-duration: .5s ;
    -webkit-animation-timing-function: step-end ;
            animation-timing-function: step-end ;
    -webkit-animation-iteration-count: infinite ;
            animation-iteration-count: infinite ;
    -webkit-animation-direction: alternate ;
            animation-direction: alternate ;
}

.blinkBordersBottomEmpty:focus, .blinkBordersBottomEmpty:hover {
    -webkit-animation: wobbleBottom 1s 1;
            animation: wobbleBottom 1s 1;
}

@-webkit-keyframes wobble {
    0% {
        border-width: 2px;
        border-color: green;
    }
}

@keyframes wobble {
    0% {
        border-width: 2px;
        border-color: green;
    }
}

@-webkit-keyframes wobbleBottom {
    0% {
        border-bottom-width: 2px;
        border-color: green;
    }
}

@keyframes wobbleBottom {
    0% {
        border-bottom-width: 2px;
        border-color: green;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu-item {
  width: 100px;
  padding: 0 40px;
  margin: 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper.active {
  border: 1px blue solid;
}

.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}
